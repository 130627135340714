import React from "react";
import { graphql } from "gatsby";

export default function ReadmePage({ data }) {
  // Use the content of the Readme node
  const readmeContent = data.markdownRemark.html;

  return (
    <>
        {/* Go back to the main page */}
        <div className="markdown-page"> 
            <a href="/">← Retour</a>
            <div dangerouslySetInnerHTML={{ __html: readmeContent }} />
        </div>
    </>
  );
}


export const query = graphql `
  query {
    markdownRemark(fileAbsolutePath: { regex: "/README.md/" }) {
      html
    }
  }
`;